<template>
  <transition
    appear
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <div v-show="showTopNav" class="lrs-header-container">
      <div class="content">
        <div class="lrs-logo">
          <router-link to="/">
            <img src="../assets/img/11301@2x.png" alt="" />
          </router-link>
        </div>
        <div class="menu">
          <router-link to="/" :class="{ active: this.$route.path == '/' }"
            >首页</router-link
          >
          <router-link
            to="/SpecialtyView"
            :class="{ active: this.$route.path == '/SpecialtyView' }"
            >擅长</router-link
          >
          <router-link
            to="/CaseView"
            :class="{
              active:
                this.$route.path == '/CaseView' ||
                this.$route.path == '/CaseDetailVIew',
            }"
            >案例</router-link
          >
          <router-link
            to="/Infoview"
            :class="{
              active:
                this.$route.path == '/Infoview' ||
                this.$route.path == '/SignDetailView' ||
                this.$route.path == '/SignNewsView' ||
                this.$route.path == '/NewDetailView',
            }"
            >资讯</router-link
          >
          <router-link
            to="/SystemView"
            :class="{ active: this.$route.path == '/SystemView' }"
            >朗睿斯建站系统</router-link
          >
          <router-link
            to="/UsView"
            :class="{ active: this.$route.path == '/UsView' }"
            >我们</router-link
          >
          <router-link
            to="/LoginView"
            :class="{ active: this.$route.path == '/LoginView' }"
            >登录</router-link
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["showTopNav"]),
  },
};
</script>

<style lang="scss" scoped>
.lrs-header-container {
  width: 100%;
  height: 94px;
  position: fixed;
  left: 50%;
  margin-left: -50%;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #dadada;
  .content {
    padding: 0 70px 0 70px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .lrs-logo {
      margin-top: 27px;
      img {
        width: 140px;
      }
    }
    .menu {
      width: 702px;
      display: flex;
      justify-content: space-between;
      margin-top: 35px;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      a {
        display: block;
        &:hover {
          transform: translateY(-5px);
          font-weight: bold;
          transition: all 0.1s linear;
        }
        transition: all 0.1s linear;
      }
    }
  }
}

.active {
  color: #c71315;
  border-bottom: 2px solid #c71315;
  font-weight: bold;
}
</style>
