<template>
	<div class="customerService">
		<div class="popUp">
			<div class="left">
				<div class="image">
					<img src="https://lrsmanagement.oss-cn-beijing.aliyuncs.com/20230524.png" />
				</div>
				
			</div>
			<div class="right">
				<el-input v-model="phone" placeholder="在此输入您的电话号码"></el-input>
				<div class="button" @click="addContactNumber">获取报价</div>
			</div>
			
		</div>
		<div class="close" @click="close">
			<img src="../static/img/close.png" />
		</div>
	</div>
</template>

<script>
	
	
	
	import { addContactNumber } from "../http/AllApi.js";
	export default{
		data(){
			return{
				phone:''
			}
		},
		methods:{
			close(){
				this.$emit('closePopUp')
			},
			addContactNumber(){
				if(this.lxdh(this.phone) == false){
					this.$message.error('请输入正确的号码！');
				} else {
					addContactNumber({phone:this.phone}).then(res => {
						this.phone = ''
						this.$emit('quotation')
					})
				}
				
				
			},
			lxdh(e) {
				var patrn = /^1[3456789]\d{9}$/; //手机电话
				var zjdh = /^((0\d{2,3})-)?(\d{7,8})$/; //座机电话
				var valx=e;
				if(patrn.test(valx)==false && zjdh.test(valx)==false){
				    return false;
				}
			}

		}
	}
</script>

<style lang="scss" scoped>
	.customerService{
		background-color: rgba(0,0,0,0.3);
		height: 123px;
		position: fixed;
		bottom: 0px;
		left: 0;
		width: 100%;
		z-index: 1000;
		.popUp{
			width: 1200px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			
			.left{
				position: relative;
				width: 217px;
				height: 123px;
				.image{
					width: 400px;
					height: 216px;
					position: absolute;
					bottom: 0;
					img{
						width: 100%;
					}
				}
				
			}
			.right{
				display: flex;
				align-items: center;
				justify-content: end;
				cursor: pointer;
				/deep/ .el-input__inner{
					height: 55px;
					line-height: 55px;
					width: 400px;
					font-size: 18px;
				}
				.button{
					width: 184px;
					line-height: 55px;
					background: #C71315;
					border-radius: 10px 10px 10px 10px;
					opacity: 1;
					font-size: 20px;
					font-family: Microsoft YaHei-Bold, Microsoft YaHei;
					font-weight: bold;
					color: #FFFFFF;
					text-align: center;
				}
			}
		}
		.close{
			position: absolute;
			width: 50px;
			height: 50px;
			right: 0;
			bottom: 73px;
			cursor: pointer;
			transform: rotate(-90deg);
			img{
				width: 100%;
				height: 100%;
			}
		}
	}
</style>