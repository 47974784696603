<template>
	<div class="index-view-container" ref="scrollDiv">
		<div class="container">
			<!-- 1-轮播图 -->

			<div class="banner" :style="h" v-if="this.banners">
				<div class="swiper-container1">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="(item,index) in banners" :key="index">
							<img style="width: 100%" :style="h" :src="item.bannnerFile" alt="" />


							<div class="totalNum" v-if="bannerIndex == index && item.companiesNum">
								<div>累计服务超
									<countTo class="counter1" :startVal="0" :endVal="item.companiesNum" :duration="2000"></countTo>
									家企业
								</div>
								<div>
									<countTo class="counter2" :startVal="0" :endVal="item.dealAmount" :decimals="1"
										:duration="2000"></countTo>亿成交额
								</div>

							</div>

							<img @click="start" class="mouse" src="../assets/img/11233.png" alt="" />
							<div class="text_wight">SCROLL</div>
						</div>
					</div>
				</div>
				<div class="dialog_box"></div>
			</div>

			<div class="scroll">
				<div class="swiper-container2 dialog_box1">
					<div class="swiper-wrapper">
						<div class="swiper-slide">张先生获取了报价...</div>
						<div class="swiper-slide">梅女士正在沟通需求...</div>
						<div class="swiper-slide">黄先生签约成功...</div>
						<div class="swiper-slide">周女士正在沟通需求...</div>
						<div class="swiper-slide">李先生正在浏览...</div>
					</div>
				</div>
			</div>

			<!-- 2你好，朗睿斯 -->
			<!-- <div v-if="changeSec" class="hello" ref="hello">
				<div class="pic">
					<img src="../assets/img/11405.png" alt="" />
				</div>

				<div class="shalom">
					<span>您好</span>
				</div>
				<div>
					<img src="../assets/img/11245.png" alt="" />
				</div>
			</div> -->
			<!-- 翻书 -->
		<!-- 	<div class="book" v-else>
				<div id="page1" ref="page1">
					<div class="second">
						<div class="text">2015&nbsp;&nbsp;&nbsp;年&nbsp;&nbsp;&nbsp;3&nbsp;&nbsp;&nbsp;月</div>
						<div class="text">
							<span>8</span><span>年</span><span>的</span><span>定</span><span>制</span><span>开</span><span>发</span><span>让</span>
						</div>
					</div>
					<div class="first">
						<div class="button"></div>
					</div>
				</div>
				<div id="page2">
					<div class="third">
						<div class="text">我&nbsp;&nbsp;&nbsp;诞&nbsp;&nbsp;&nbsp;生&nbsp;&nbsp;&nbsp;了&nbsp;&nbsp;&nbsp;!
						</div>
						<div class="text">
							<span>朗</span><span>睿</span><span>斯</span><span>能</span><span>为</span><span>您</span><span>提</span><span>供:</span>
						</div>
					</div>
				</div>
			</div> -->
		</div>

		<!-- 3全方位技术 -->
		<div class="lrs_tec">
			<div class="container">
				<div class="title wow fadeInUp" style="text-align: center;width: 100%;">全方位技术覆盖+解决方案</div>
				<div class="module">
					<div class="left_menu">
						<div @mouseenter="changeMenu(i)" v-for="(menu, i) in menus" :key="i"
							:class="{ active: now == i, noactive: now != i }" class="wow fadeInLeftBig"
							:data-wow-delay="i / 8 + 's'">
							{{ menu.technologyName }}
						</div>
					</div>
					<div>

						<div class="wow fadeInRightBig rightImg">
							<el-carousel trigger="click" height="41.25rem">
								<el-carousel-item v-for="(item,index) in menus[now].imageList" :key="index">
									<div class="textContent">
										<div class="textTitle">{{menus[now].technologyName}}</div>
										<div class="introduce">
											{{menus[now].introduce}}
										</div>
									</div>
									<el-image style="width: 100%; height: 100%" :src="item" fit="cover"></el-image>
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 4经典案例 -->
		<div class="classic_case">
			<div class="container">
				<div class="titile wow fadeInUp">经典案例</div>
				<div class="dec wow fadeInUp" data-wow-delay="0.5s">
					从项目立项，到需求落地，以及整个产品设计开发上线朗睿斯全程参与到目前位置项目运行稳定
				</div>
				<div class="case">
					<div class="card" v-for="item in caseList" :key="item.id">
						<div class="caption wow fadeInUp">
							{{ item.caseName }}
						</div>
						<div class="subtitle wow fadeInUp" data-wow-delay="0.5s">
							{{ item.introduce }}
						</div>
						<div class="line"></div>
						<div class="pic">
							<div class="wow fadeInLeftBig">
								<img :src="item.images3[0].caseImage" alt="" />
							</div>
							<div class="wow fadeInRightBig">
								<img :src="item.images3[1].caseImage" alt="" />
							</div>
						</div>
					</div>
				</div>
				<div @click="$router.push('/CaseView')" class="button wow slideInDown" data-wow-duration="2s">
					加载更多
				</div>
			</div>
		</div>
		<!-- 5您找人做项目肯定遇到过 -->
		<div class="problem">
			<div class="container">
				<div class="title wow fadeInUp">
					<div>您找人做项目肯定遇到过</div>
					<div>?</div>
				</div>
				<div class="subproblem">
					<div class="item wow fadeInUp" v-for="(item2, index) in serves2" :key="item2.id"
						:data-wow-delay="index / 3 + 's'">
						<img :src="item2.img" alt="" />
						<div class="text">
							<div>{{ item2.title }}</div>
							<div>{{ item2.sec_title }}</div>
						</div>
					</div>
				</div>
				<div class="pic wow fadeIn">
					<img src="https://lrsmanagement.oss-cn-beijing.aliyuncs.com/11406.6495320d.png" alt="" />
				</div>
				<div class="sec_title wow fadeInUp">朗睿斯为解决您的烦恼提供</div>
				<div class="subhead wow fadeInUp" data-wow-delay="0.5s">
					3个一对一的可视化精准服务
				</div>
				<div class="serves">
					<div class="serve wow fadeInUp" v-for="(item, index) in serves" :key="item.id"
						:data-wow-delay="index / 5 + 's'">
						<img :src="item.icon" alt="" />
						<div class="title" style="text-align: center;width: 100%;justify-content: center;">{{ item.title }}</div>
						<div class="sec_title">{{ item.sec_title }}</div>
						<div class="button" @click="open">
							立即咨询
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 6朗睿斯荣誉 -->
		<div class="honor">
			<div class="container">
				<div class="title wow fadeInUp ry">朗睿斯荣誉</div>
				<div class="icon wow fadeInUp"></div>
				<div class="medal">
					<div class="wow fadeInRightBig">
						<img class="modal1" src="../assets/img/_20220620174913.png" alt="" />
					</div>
					<div class="wow fadeInRightBig" data-wow-delay="0.3s">
						<img class="modal2" src="../assets/img/11321.png" alt="" />
					</div>
					<div class="wow fadeInRightBig" data-wow-delay="0.6s">
						<img class="modal3" src="../assets/img/11322.png" alt="" />
					</div>
					<div class="wow fadeInRightBig" data-wow-delay="0.9s">
						<img class="modal4" src="../assets/img/11323.png" alt="" />
					</div>
				</div>
			</div>
		</div>

		<lrs-contact></lrs-contact>
	</div>
</template>

<script>
	import LrsContact from "@/components/LrsContact.vue";

	import {
		WOW
	} from "../../node_modules/wowjs/dist/wow";
	// 数字滚动
	import countTo from "vue-count-to";
	// swiper
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";

	// 接口
	import {
		Getbanner,
		TechnologyIntroducedList,
		queryProjectCase,
		addArtificial
	} from "../http/AllApi.js";
	export default {
		components: {
			LrsContact,
			countTo
		},
		data() {
			return {
				inTransition: false,
				inAnimation: false,
				changeSec: true,
				rig_imgs: ["119", "10021", "119", "119", "119", "119", "119"],
				banners: [],
				menus: [{
					imageList: []
				}],

				caseList: [],

				classic_cases: [{
						id: 1,
						title: "无人驾驶巡逻系统",
						description: "能量管理系统（EMS）、电动助力转向系统（EPS）、电机驱动控制系统",
						img1: require("../assets/img/10011.png"),
						img2: require("../assets/img/10012.png"),
					},
					{
						id: 2,
						title: "新能源电动汽车控制系统",
						description: "能量管理系统（EMS）、电动助力转向系统（EPS）、电机驱动控制系统",
						img1: require("../assets/img/10021.png"),
						img2: require("../assets/img/10022.png"),
					},
					{
						id: 3,
						title: "广告终端智能控制平台",
						description: "能量管理系统（EMS）、电动助力转向系统（EPS）、电机驱动控制系统",
						img1: require("../assets/img/10031.png"),
						img2: require("../assets/img/10032.png"),
					},
				],
				serves: [{
						id: 1,
						title: "价格",
						icon: require("../assets/img/11330.png"),
						sec_title: "资深产品经理一对一的项目需求梳理，详细功能报价可按预算做删减，按照人工每天报价使价格透明",
					},
					{
						id: 2,
						title: "质量",
						icon: require("../assets/img/11331.png"),
						sec_title: "资深产品经理和运营一对一的参与需求，提供产品落地和运营建议，严格代码管理和开发规范管理",
					},
					{
						id: 3,
						title: "售后",
						icon: require("../assets/img/11332.png"),
						sec_title: "提供管理系统一对一，手机，电脑时时查看开发进度和问题反馈，交付源码，开发文档各种源文件使迭代无忧",
					},
				],
				serves2: [{
						id: 1,
						img: require("../assets/img/11339.png"),
						title: "开发工期",
						sec_title: "开发工期一拖再拖，迟迟看不到东西，新增加功能遥遥无期",
					},
					{
						id: 2,
						img: require("../assets/img/11340.png"),
						title: "开发费用",
						sec_title: "报价有底有高无从选择，报价超出预算，合作后新增功能费用天价",
					},
					{
						id: 3,
						img: require("../assets/img/11335.png"),
						title: "项目质量",
						sec_title: "bug多自己变成测试，迭代文档不全，代码质量差接手后只能重新开发",
					},
					{
						id: 4,
						img: require("../assets/img/11336.png"),
						title: "售后服务",
						sec_title: "解决问题慢，联系不上人，公司说不做就不做",
					},
				],
				current: 0,
				now: 0,
				height: 0,
				timer: null,
				isOnshow: false,
				location: null,
				changeName: "change",
				dsq: '',
				h: {
					height: null,
				},
				totalShow:false,
				bannerIndex:0
			};
		},

		watch: {
			location(newValue, oldValue) {
				if (newValue >= parseInt(this.h.height) - 94) {
					// if (newValue >= 866) {
					clearInterval(this.timer);
					this.timer = null;
					this.location = null;
					if (this.changeSec) {
						this.trigger();
					}
				}
			},
		},

		destroyed() {
			clearInterval(this.dsq)
		},

		mounted() {
			this.getbanner();
			this.TechnologyIntroducedList()
			this.queryProjectCase()


			let that = this
			setTimeout(function() {
				that.regionalCalculation('首页')
			}, 1500)
			this.dsq = setInterval(function() {
				that.regionalCalculation('首页')
			}, 20000)
			this.h.height = window.innerHeight + "px";

			// 监听页面滚动事件
			window.addEventListener("wheel", this.stop);
			window.addEventListener("scroll", this.scrollChange);

			this.$store.state.showTopNav = false;

			// 滚动条动画
			let wow = new WOW({
				boxClass: "wow",
				animateClass: "animated",
				offset: 200,
				mobile: true,
				live: true,
			});
			wow.init();


		},

		methods: {
			//区域计算
			regionalCalculation(page) {
				//页面总高度
				let boxHeight = document.body.clientHeight
				//窗口高度
				let windowHeight = window.innerHeight
				//滚动条高度
				let scrollHeight = document.documentElement.scrollTop
				//当前位置
				let currentLocation = windowHeight + scrollHeight

				//获取当前位置百分比
				let depth = Math.round((currentLocation / boxHeight) * 100)
				addArtificial({
					clickPlace: page,
					depth: depth
				}).then(res => {

				})
			},
			open() {
				this.$store.commit("open_information", true);
			},
			// 获取banner图
			getbanner() {
				let that = this
				Getbanner({
					bannnerType: 1
				}).then((res) => {
					console.log("banner图", res.data.data);
					if (res.data.status == 200) {
						this.banners = res.data.data;
						setTimeout(function() {
							let mySwiper = new Swiper(".swiper-container1", {
								effect: "fade",
								speed: 1000,
								autoplay: {
									delay: 5000,
									pauseOnMouseEnter: false,
								},
								on:{
									slideChangeTransitionEnd:function(){
										that.bannerIndex = mySwiper.activeIndex
									}
								}
							});
							new Swiper(".swiper-container2", {
								direction: "vertical", // 垂直切换选项
								effect: "cube",
								cubeEffect: {
									slideShadows: false,
									shadow: false,
									shadowOffset: 0,
									shadowScale: 0,
								},
								loop: true,
								speed: 5000,
								autoplay: {
									delay: 1000,
									pauseOnMouseEnter: false,
								}
								
							});
							
							that.totalShow = true
						}, 500)
					}
				});
			},

			//获取技能列表
			TechnologyIntroducedList() {
				TechnologyIntroducedList({}).then(res => {
					this.menus = res.data.data
				})
			},

			//获取案例
			queryProjectCase() {
				queryProjectCase({
					currentPage: 1,
					pageSize: 10,
					isFirstShow: 1
				}).then(res => {
					this.caseList = res.data.data.list
				})
			},

			stop() {
				clearInterval(this.timer);
				this.timer = null;
				this.location = null;
			},
			trigger() {
				this.changeSec = false;
				this.pageTurn = true;
			},
			changeMenu(i) {
				this.now = i;
			},

			scrollChange() {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				this.location = scrollTop;
				if (scrollTop <= 0) {
					this.$store.state.showTopNav = false;
				} else {
					this.$store.state.showTopNav = true;
				}
			},
			start() {
				this.timer = setInterval(() => {
					this.location = this.location + 10;
					window.scrollTo(0, this.location);
				}, 10);
			},
		},
		destroyed() {
			clearInterval(this.timer);
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.scrollChange);
			window.removeEventListener("wheel", this.stop);
			this.$store.state.showTopNav = true;
		},
	};
</script>

<style lang="scss" scoped>
	.container {
		width: 100%;
		margin: 0 auto;

		//  <!--------------------- 1-轮播图 ---------------------->
		.banner {
			width: 100%;
			overflow: hidden;
			position: relative;

			// .counter1,
			// .counter2 {
			// 	position: absolute;
			// 	font-size: 60px;
			// 	font-family: DINCond-Medium, DINCond;
			// 	font-weight: 500;
			// 	color: #c71315;
			// 	top: 540px;
			// }

			// .counter1 {
			// 	left: 360px;
			// }

			// .counter2 {
			// 	left: 555px;
			// }

			.title {
				z-index: 1;
				position: absolute;
				top: 355px;
				left: 258px;
			}

			.mouse {
				cursor: pointer;
				z-index: 1;
				position: absolute;
				width: 34px;
				height: 56px;
				bottom: 90px;
				left: 0;
				right: 0;
				margin: 0 auto;
				animation: move 0.7s ease infinite;

				&:hover {
					animation-play-state: paused;
					cursor: pointer;
				}
			}

			@keyframes move {
				0% {
					transform: translateY(0);
				}

				25% {
					transform: translateY(-5px);
				}

				50% {
					transform: translateY(0px);
				}

				75% {
					transform: translateY(5px);
				}

				100% {
					transform: translateY(0);
				}
			}

			.text_wight,
			.text_black {
				z-index: 1;
				position: absolute;
				bottom: 50px;
				left: 0px;
				right: 0;
				margin: 0 auto;
				width: 80px;
				height: 20px;
				font-size: 18px;
				font-family: Arial-Bold, Arial;
				font-weight: bold;
				color: #333333;
			}

			.text_wight {
				color: #333333;
			}

			.text_black {
				color: #ffffff;
			}
		}

		//  <!------------------- 2你好，朗睿斯 -------------------->
		.hello {
			width: 100%;
			height: 882px;
			background: #f6f6f6;
			overflow: hidden;
			position: relative;

			.pic {
				width: 536px;
				position: absolute;
				top: 100px;
				left: 52%;
				margin-left: -268px;
				z-index: 1;
				cursor: pointer;

				img {
					width: 100%;
				}
			}

			.shalom {
				position: absolute;
				top: 183px;
				left: 50%;
				margin-left: -125px;
				z-index: 2;
				width: 250px;
				height: 132px;
				/* 字体分散对齐 */
				text-align: justify;
				text-align-last: justify;
				font-size: 100px;
				font-family: Microsoft YaHei-Bold, Microsoft YaHei;
				font-weight: bold;
				color: #000000;
			}

			& div:last-child {
				position: absolute;
				top: 389px;
				left: 50%;
				margin-left: -481px;
				z-index: 2;
				width: 952px;
				height: 60px;

				img {
					width: 100%;
				}
			}
		}

		.book {
			width: 100%;
			height: 882px;
			position: relative;
			background: #f6f6f6;
			/* 设置元素的子元素是位于3D空间还是平面中 */
			transform-style: preserve-3d;
			/* 景深效果：由远及近，从大到小 */
			// perspective: 1500px;
			perspective: 3500px;

			.pageturn {
				transition: all 5s;
				transform: rotateY(-180deg);
			}
		}

		#page1 {
			z-index: 1;
			animation: pageturn 3s forwards;
			width: 600px;
			height: 582px;
			transform-origin: left;
			transform-style: preserve-3d;
			position: absolute;
			top: 100px;
			left: 50%;

			.first {
				position: absolute;
				left: 0;
				top: 0;
				display: flex;
				justify-content: right;
				align-items: center;
				width: 600px;
				height: 582px;
				border-radius: 30px;
				background: #fff;
				/* 元素背向观察者时，不可见 */
				backface-visibility: hidden;

				.button {
					width: 12px;
					height: 60px;
					border-radius: 5px;
					background: #f9e7e7;
					margin-right: 20px;
					margin-top: 218px;
				}
			}

			.second {
				position: absolute;
				left: 0;
				top: 0;
				border-radius: 30px;
				width: 600px;
				height: 582px;
				background: #eff0f2;
				left: 10px;
				transform: rotateY(180deg);

				div {
					font-size: 50px;
					font-family: Microsoft YaHei-Bold, Microsoft YaHei;
					font-weight: bold;
					color: #000000;
				}

				& div:first-child {
					margin-left: 190px;
					margin-top: 204px;
					animation: change 10s;
					display: flex;
					justify-content: space-between;
				}

				& div:last-child {
					display: flex;
					justify-content: space-between;
					margin-top: 80px;
					margin-left: 30px;
					margin-right: 30px;
					text-align: left;
					animation: change 10s;
				}
			}
		}

		#page2 {
			z-index: 2;
			width: 600px;
			height: 582px;
			transform-origin: left;
			transform-style: preserve-3d;
			position: absolute;
			top: 100px;
			left: 50%;
			margin-right: 10px;

			.third {
				width: 600px;
				height: 582px;
				border-radius: 30px;
				background: #fff;
				z-index: 10;
				box-sizing: border-box;
				padding-top: 204px;

				div {
					font-size: 50px;
					font-family: Microsoft YaHei-Bold, Microsoft YaHei;
					font-weight: bold;
					color: #000000;
				}

				& div:first-child {
					width: 380px;
					margin-left: 10px;
					animation: change 10s;
				}

				& div:last-child {
					margin-left: 10px;
					margin-right: 30px;
					margin-top: 80px;
					animation: change 10s;
					display: flex;
					justify-content: space-between;
				}
			}
		}

		@keyframes pageturn {

			/* 100%完成所有动作的最后一个静止帧 */
			100% {
				transform: rotateY(-180deg);
			}
		}

		@keyframes change {

			/* 0%没有任何动作第一帧 */
			0% {
				opacity: 0;
			}

			/* 100%完成所有动作的最后一个静止帧 */
			100% {
				opacity: 1;
			}
		}

		// @keyframes typing {
		//   from {
		//     width: 0;
		//   }
		// }
		// @keyframes blink-caret {
		//   50% {
		//     border-color: transparent;
		//   }
		// }
		// .text {
		//   color: #ffffff;
		//   font: 200% monospace;
		//   border-right: 10px solid;
		//   width: 434px;
		//   white-space: nowrap;
		//   overflow: hidden;
		//   animation: typing 3.5s steps(7, end),
		//     blink-caret 0.5s step-end infinite alternate;
		// }
	}

	//   <!------------------------- 3全方位技术---------------------->
	.lrs_tec {
		width: 100%;
		overflow: hidden;
		.container {
			width: 1404px;
			margin: 0 auto;
			padding-bottom: 150px;
			.title {
				margin: 150px auto 100px;
				width: 730px;
				height: 82px;
				font-size: 62px;
				font-family: Microsoft YaHei-Bold, Microsoft YaHei;
				font-weight: bold;
			}
			

			.module {
				height: 660px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;

				.left_menu {
					width: 300px;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					& .noactive {
						width: 300px;
						height: 80px;
						text-align: center;
						line-height: 80px;
						background: #eff0f2;
						font-size: 22px;
						font-family: Microsoft YaHei-Bold, Microsoft YaHei;
						font-weight: bold;
						cursor: pointer;
					}

					& .active {
						width: 300px;
						height: 80px;
						text-align: center;
						line-height: 80px;
						font-size: 22px;
						font-family: Microsoft YaHei-Bold, Microsoft YaHei;
						font-weight: bold;
						cursor: pointer;
						color: #ffffff;
						box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
						background: linear-gradient(268deg, #f5233f 0%, #a8002a 100%);
					}

					& div:first-child {
						border-radius: 20px 20px 0px 20px;
					}

					& div:last-child {
						border-radius: 20px 0px 20px 20px;
					}
				}

				.rightImg {
					width: 1054px;
					height: 860px;
					position: relative;
					.textContent{
						position: absolute;
						top:0;
						left:0;
						z-index:1;
						padding:60px;
						.textTitle{
							line-height: 52px;
							font-size: 40px;
							font-family: Microsoft YaHei-Bold, Microsoft YaHei;
							font-weight: bold;
							color: #FFFFFF;
						}
						.introduce{
							line-height: 42px;
							font-size: 26px;
							font-family: Microsoft YaHei-Bold, Microsoft YaHei;
							color: #FFFFFF;
							margin-top: 20px;
						}
					}
				}

				.pic {
					width: 1054px;
				}
			}
		}
	}

	//     <!--------------------- 4经典案例 ----------------------->
	.classic_case {
		width: 100%;
		background: #f6f6f6;
		overflow: hidden;

		.container {
			width: 1404px;
			margin: 0 auto;
			overflow: hidden;

			.titile {
				text-align: center;
				font-size: 62px;
				font-family: Microsoft YaHei-Bold, Microsoft YaHei;
				font-weight: bold;
				color: #000000;
				margin: 150px auto 0;
			}

			.dec {
				width: 1240px;
				font-size: 40px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #626364;
				margin: 30px auto 0;
				text-align: center;
				line-height: 60px;
			}

			.case {
				margin-top: 100px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.card {
					width: 1404px;
					background: #eff0f2;
					border-radius: 20px;
					overflow: hidden;
					box-sizing: border-box;
					padding: 34px 50px 50px;
					margin-top: 50px;

					.caption {
						font-size: 22px;
						font-family: Microsoft YaHei-Bold, Microsoft YaHei;
						font-weight: bold;
						color: #333333;
					}

					.subtitle {
						font-size: 18px;
						font-family: Microsoft YaHei-Regular, Microsoft YaHei;
						font-weight: 400;
						color: #626364;
						margin-top: 20px;
					}

					.line {
						margin-top: 33px;
						border-bottom: 1px solid #a6a7a8;
					}

					.pic {
						width: 100%;
						display: flex;
						justify-content: space-between;
						margin-top: 50px;

						img {
							display: inline-block;
							width: 626px;
						}
					}
				}
			}

			.button {
				width: 155px;
				height: 41px;
				border-radius: 4px;
				opacity: 1;
				border: 1px solid #e6e6e6;
				margin: 100px auto 150px;
				font-size: 14px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #626364;
				text-align: center;
				line-height: 41px;
				cursor: pointer;

				&:hover {
					box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
					color: #000;
				}
			}
		}
	}

	// 5您找人做项目肯定遇到过
	.problem {
		overflow: hidden;
		width: 100%;

		.container {
			overflow: hidden;
			width: 1404px;
			margin: 0 auto;

			.title {
				width: 744px;
				height: 119px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 150px auto 0;

				& div:first-child {
					font-size: 62px;
					font-family: Microsoft YaHei-Bold, Microsoft YaHei;
					font-weight: bold;
					color: #000000;
				}

				& div:last-child {
					font-size: 90px;
					font-family: Microsoft YaHei-Bold, Microsoft YaHei;
					font-weight: bold;
					color: #000000;
				}
			}

			.subproblem {
				height: 246px;
				margin-top: 115px;
				display: flex;
				justify-content: space-between;
				flex-flow: row wrap;

				.item {
					width: 702px;
					height: 73px;
					display: flex;

					img {
						width: 73px;
						height: 73px;
					}

					.text {
						margin-left: 20px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						& div:first-child {
							height: 29px;
							font-size: 22px;
							font-family: Microsoft YaHei-Bold, Microsoft YaHei;
							font-weight: bold;
							color: #333333;
						}

						& div:last-child {
							height: 24px;
							font-size: 18px;
							font-family: Microsoft YaHei-Regular, Microsoft YaHei;
							font-weight: 400;
							color: #626364;
						}
					}
				}
			}

			.pic {
				margin-top: 150px;

				img {
					width: 100%;
				}
			}

			.sec_title {
				height: 82px;
				font-size: 62px;
				font-family: Microsoft YaHei-Bold, Microsoft YaHei;
				font-weight: bold;
				color: #000000;
				margin: 100px auto 0;
				text-align: center;
			}

			.subhead {
				text-align: center;
				height: 52px;
				font-size: 40px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #626364;
				margin: 30px auto;
			}

			.serves {
				width: 1404px;
				height: 528px;
				margin: 100px auto 150px;
				display: flex;
				justify-content: space-between;

				.serve {
					width: 434px;
					height: 528px;
					background: #f6f6f6;
					border-radius: 20px;

					img {
						display: block;
						width: 90px;
						height: 90px;
						margin: 94px auto 0;
					}

					.title {
						text-align: center;
						height: 29px;
						font-size: 22px;
						font-family: Microsoft YaHei-Bold, Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						margin: 50px auto 0;
					}

					.sec_title {
						width: 342px;
						height: 80px;
						font-size: 18px;
						font-family: Microsoft YaHei-Regular, Microsoft YaHei;
						font-weight: 400;
						color: #626364;
						margin: 20px auto 0;
						text-align: center;
						line-height: 28px;
					}

					.button {
						width: 155px;
						height: 42px;
						background: #f6f6f6;
						border-radius: 4px;
						border: 1px solid #c71315;
						margin: 50px auto 0;
						font-size: 18px;
						font-family: Microsoft YaHei-Regular, Microsoft YaHei;
						font-weight: 400;
						color: #c71315;
						line-height: 42px;
						text-align: center;
						cursor: pointer;

						&:hover {
							box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
						}
					}
				}
			}
		}
	}

	// ---------------------6朗睿斯荣誉-----------------------------
	.honor {
		width: 100%;
		height: 975px;
		background-image: url("../assets/img/11407.png");
		overflow: hidden;

		.container {
			overflow: hidden;
			width: 1404px;
			margin: 0 auto;

			.title {
				height: 82px;
				font-size: 62px;
				font-family: Microsoft YaHei-Bold, Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				margin-top: 150px;
				margin-left: 281px;
				text-align: center;
			}
			.ry{
				text-align: left;
			}

			.icon {
				width: 60px;
				height: 4px;
				background: #c71315;
				opacity: 1;
				margin-top: 30px;
				margin-left: 281px;
			}

			.medal {
				overflow: hidden;
				width: 1404px;
				height: 460px;
				background: #ffffff;
				border-radius: 20px 20px 0px 0px;
				margin: 100px auto 0;
				display: flex;

				& div:nth-child(1) {
					margin-left: 115px;
					margin-top: 47px;

					img {
						width: 103px;
					}
				}

				& div:nth-child(2) {
					margin-left: 82px;
					margin-top: 154px;

					img {
						width: 300px;
					}
				}

				& div:nth-child(3) {
					margin-left: 45px;
					margin-top: 154px;

					img {
						width: 300px;
					}
				}

				& div:nth-child(4) {
					margin-left: 45px;
					margin-top: 154px;

					img {
						width: 300px;
					}
				}
			}
		}
	}
</style>
<style lang="scss">
	.dialog_box1 {
		position: fixed;
		z-index: 100;
		top: 166px;
		right: 70px;
		width: 317px;
		height: 44px;
		overflow: hidden;

		.swiper-slide {
			background: rgba(252,182,183,0.1);
			border-radius: 90px;
			line-height: 44px;
			padding-left: 20px;
			box-sizing: border-box;
			font-size: 20px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #626364;
			letter-spacing: 5px;
		}
	}

	.dialog_box2 {
		position: absolute;
		z-index: 9;
		top: 166px;
		right: 70px;
		width: 317px;
		height: 44px;
		overflow: hidden;

		.swiper-slide {
			background: #3a3a3a;
			border-radius: 90px;
			line-height: 44px;
			padding-left: 20px;
			box-sizing: border-box;
			font-size: 20px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #ffffff;
			letter-spacing: 5px;
		}
	}

	.totalNum {
		position: absolute;
		top: 610px;
		left: 258px;
		z-index: 10;
		display: flex;
		font-size: 20px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #333333;

		span {
			font-size: 60px;
			font-family: DINCond-Medium, DINCond;
			font-weight: 500;
			color: #C71315;
			margin: 0 4px;
		}

		div {
			margin-left: 34px;

			&:first-child {
				margin-left: 0;
			}
		}
	}
</style>