<template>
	<div class="modal_chat" v-if="this.$store.state.information">
		<div class="chat_title">
			<p>点击免费获取报价</p>
			<div class="chat_switch" @click="open()">
				<div class="chat_down" v-if="this.$store.state.information">
					<img src="../static/img/close1.png" />
				</div>
				<div class="chat_left" v-if="!this.$store.state.information">
					<img src="../static/img/add.png" />
				</div>
			</div>
		</div>
		<div class="chat_main">
			<div class="chat_desc">
				<p>重庆朗睿斯科技有限公司欢迎您:</p>
				<span>13996291621（咨询电话）</span>
			</div>
			<div class="chat_form">
				<div class="form_item">
					<el-input v-model="form.demand" type="textarea" rows="4" resize="none" placeholder="如:我需要开发一个直播APP"></el-input>
				</div>
				
				<div class="form_item">
					<el-input v-model="form.userName" placeholder="姓名(必填)"></el-input>
				</div>
				
				<div class="form_item">
					<el-input v-model="form.phone" placeholder="微信号/电话"></el-input>
				</div>
				
			</div>
		</div>

		<div class="chat_submit" @click="addDemand" v-if="this.$store.state.information">
			立即提交
		</div>


	</div>
</template>

<script>
	import { addDemand } from "../http/AllApi.js";
	export default{
		data(){
			return{
				form:{
					demand:'',
					userName:'',
					phone:''
				}
			}
		},
		methods:{
			open(){
				if(this.$store.state.information){
					this.$store.commit("open_information", false);
				} else {
					this.$store.commit("open_information", true);
				}
				
			},
			addDemand(){
				
				if(!this.form.demand){
					this.$message.error('请输入留言内容！');
				}
				if(!this.form.userName){
					this.$message.error('请输入姓名！');
				}
				if(!this.form.phone){
					this.$message.error('请输入微信号或者电话！');
				}
				
				
				
				addDemand(this.form).then(res => {
					if(res.data.status == 200){
						this.form.demand = ''
						this.form.userName = ''
						this.form.phone = ''
						this.$store.commit("open_information", false);
						this.$message({
						    message: '提交成功！项目经理将会在2小时内向您联系！',
						    type: 'success'
						});
					} else{
						this.$message.error(res.data.message);
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.modal_chat {
		font-size: 14px;
		position: fixed;
		top: 50%;
		left: 50%;
		width: 400px;
		height: 510px;
		background-color: #fff;
		border-radius: 8px;
		font-size: 14px;
		overflow: hidden;
		box-shadow: 0 0 12PX 0px #9b9b9b;
		z-index: 1000;
		margin-left: -200px;
		margin-top: -255px;
		
		.chat_title {
			height: 70px;
			color: #fff;
			background-color: #C71315;
			padding: 0 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				font-weight: bold;
			}

			.chat_switch {
				height: 20px;
				width: 20px;
				position: relative;
				cursor: pointer;
				img{
					width: 100%;
					height: 100%;
				}
			}
		}

		.chat_main {
			padding: 20px;

			.chat_desc {
				font-size: 18px;
				p {
					
				}

				span {
					color: red;
				}
			}

			.chat_form {
				.form_item{
					
					margin-top: 20px;
					/deep/ .el-textarea__inner{
						font-size: 16px;
					}
					/deep/ .el-input__inner{
						font-size: 16px;
						height: 50px;
						line-height: 50px;
					}
				}
			}
		}

		.chat_submit {
			text-align: center;
			line-height: 50px;
			color: #fff;
			background: #C71315;
			border: none;
			
			cursor: pointer;
			border-radius: 5px;
			margin: 20px;
			margin-top: 10px;
			font-size: 18px;
		}
	}
</style>