import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css/animate.min.css';
import "animate.css";

import "./utils/rem"

// axios
import axios from 'axios';
import VueAxios from 'vue-axios';
import "./assets/css/base.css";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// elementui
Vue.use(ElementUI);
Vue.use(VueAxios, axios);
// swiper
import VAS from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'

Vue.use(VAS)

Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next()
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
