<template>
  <transition
    appear
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <div class="lrs-sidebar" v-show="showSidebarNav">
		<!-- <a target="_blank" href="https://affim.baidu.com/unique_48120452/chat?siteId=19263219&userId=48120452&siteToken=21927c63077cb92d6e831091c1352d73">
			<div>
			    <img src="../assets/icon/img30.png" alt="" />
			    <p>在线咨询</p>
			  </div>
		</a> -->
		
		<div @click="open">
		    <img src="../assets/icon/img30.png" alt="" />
		    <p>在线咨询</p>
		</div>
	
      <div class="item" @click="phoneShow = !phoneShow">
        <img src="../assets/icon/img3.png" alt="" />
        <p>电话咨询</p>
		<transition name="el-fade-in-linear">
			<div class="phone" v-if="phoneShow">
				13996291621
				<span class="yjt"></span>
			</div>
		</transition>
      </div>
      <div class="item" @click="qrCodeShow = !qrCodeShow">
        <img src="../assets/icon/img4.png" alt="" />
        <p>微信咨询</p>
		<transition name="el-fade-in-linear">
			<div class="qrCode" v-if="qrCodeShow">
				<img src="../assets/img/23.png">
				<span class="yjt"></span>
			</div>
		</transition>
      </div>
      <div class="item" @click="goTop">
        <img src="../assets/icon/img5.png" alt="" />
        <p>TOP</p>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      location: null,
	  phoneShow:false,
	  qrCodeShow:false
    };
  },
  computed: {
    ...mapState(["showSidebarNav"]),
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.sideChange);
    window.addEventListener("wheel", this.stop);
    this.$store.state.showSidebarNav = false;
  },
  watch: {
    location(newValue, oldValue) {
      // console.log(newValue, oldValue);

      if (newValue <= 0) {
        clearInterval(this.timer);
        this.timer = null;
        this.location = 0;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollChange);
  },
  methods: {
	  open() {
	  	this.$store.commit("open_information", true);
	  },
    stop() {
      clearInterval(this.timer);
      this.timer = null;
      this.location = null;
    },
    goTop() {
      this.timer = setInterval(() => {
        this.location = this.location - 30;
        window.scrollTo(0, this.location);
      }, 10);
    },
    sideChange() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.location = scrollTop;
      if (scrollTop <= 0) {
        this.$store.state.showSidebarNav = false;
      } else {
        this.$store.state.showSidebarNav = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lrs-sidebar {
  position: fixed;
  z-index: 10;
  right: 0;
  top: 300px;
  div {
    width: 100px;
    height: 100px;
    background: #e11d1d;
    border-radius: 8px;
    margin-bottom: 2px;
    text-align: center;
    cursor: pointer;
    img {
      margin-top: 10px;
      width: 41px;
    }
    p {
      margin: 5px;
      font-size: 18px;
      color: #fff;
    }
  }
  .item{
	  position: relative;
	  .phone{
		  position: absolute;
		  top: 50%;
		  margin-top: -20px;
		  background-color: #e11d1d;
		  right: 120px;
		  line-height: 40px;
		  height: 40px;
		  padding: 0 15px;
		  color: #fff;
		  .yjt{
				position: absolute;
				right: -10px;
				top: 14px;
				margin-left: 110px;
				float: left;
				width: 0; 
				height: 0;
				border-width: 5px;
				border-style: solid;
				border-color: transparent transparent transparent #e11d1d;
			}
	  }
	  .qrCode{
		  position: absolute;
		  width: 100px;
		  height: 100px;
		  background-color: #e11d1d;
		  right: 120px;
		  top: 50%;
		  margin-top: -50px;
		  img{
			  width: 80px;
			  height: 80px;
			  margin: 10px auto;
		  }
		  .yjt{
		  		position: absolute;
				right: -10px;
		  		top: 14px;
		  		margin-left: 110px;
		  		float: left;
		  		width: 0; 
		  		height: 0;
		  		border-width: 5px;
		  		border-style: solid;
		  		border-color: transparent transparent transparent #e11d1d;
			}
	  }
  }
}
</style>
