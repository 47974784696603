<template>
  <div class="lrs-footer-container">
    <div class="part1">
      <div class="container">
        <div class="name">朗睿斯科技</div>
        <div class="address">
          <div class="floor-2-1">LONRIS TECH</div>
          <div class="floor-2-2">重庆市九龙坡区石桥铺一城 · 精英国际19F</div>
        </div>
        <hr />
        <div class="contact_info">
          <img src="../assets/img/23.png" style="width: 80px;height:80px;" alt="" />
          <div class="phone">
            <div>咨询电话（Tel）：</div>
            <div>13996291621</div>
          </div>
        </div>
      </div>
    </div>
    <div class="part2">
      <div class="container">
        <div class="copyright">
          copyright © 2015-2025 朗睿斯 版权所有 渝ICP备18001641号-1
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.lrs-footer-container {
  width: 100%;
  margin: 0 auto;
  .part1 {
    width: 100%;
    height: 306px;
    background-color: #191919;
    overflow: hidden;
    .container {
      width: 1404px;
      margin: 0 auto;
      .name {
        height: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-top: 54px;
      }
      .address {
        width: 100%;
        height: 29px;
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        align-items: end;
        & div:first-child {
          height: 29px;
          font-size: 22px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
        }
        & div:last-child {
          height: 19px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.8;
        }
      }
      hr {
        border: 1px solid #313131;
      }
      .contact_info {
        display: flex;
        margin-top: 28px;
        .phone {
          margin-left: 24px;
          margin-top: 13px;
          height: 54px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          & div:first-child {
            height: 19px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            opacity: 0.6;
          }
          & div:last-child {
            width: 159px;
            height: 29px;
            font-size: 22px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }
  }
  .part2 {
    width: 100%;
    height: 64px;
    background: #141414;
    overflow: hidden;
    .container {
      width: 1404px;
      margin: 0 auto;
      .copyright {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 64px;
        opacity: 0.3;
      }
    }
  }
}
</style>
