<template>
  <div id="app">
    <lrs-sidebar></lrs-sidebar>

    <lrs-header></lrs-header>

    <router-view />
    <lrs-footer></lrs-footer>
	<transition name="el-fade-in-linear">
		<customerService v-if="show" @quotation="quotation" @closePopUp="closePopUp"></customerService>
	</transition>
	<tips @closeTips="closeTips" v-if="tipsShow"></tips>
	<onlineConsultation></onlineConsultation>
  </div>
</template>
<script>
import "./assets/css/base.css";
import LrsFooter from "./components/LrsFooter.vue";
import LrsHeader from "./components/LrsHeader.vue";
import LrsSidebar from "./components/LrsSidebar.vue";
import customerService from "./components/customerService.vue";
import tips from "./components/tips.vue";
import onlineConsultation from "./components/onlineConsultation.vue"
export default {
  components: { LrsHeader, LrsFooter, customerService,tips,LrsSidebar,onlineConsultation },
  data(){
	 return{
		 show:true,
		 tipsShow:false,
		 pageShow:false
	 }
  },
  mounted() {
  },
  methods:{
	  closePopUp(){
		  this.show = false
		  let that = this
		  setTimeout(function(){
			  that.show = true
		  },30000)
	  },
	  quotation(){
		  this.show = false
		  this.tipsShow = true
	  },
	  closeTips(){
		  this.tipsShow = false
	  },
  }
};
</script>

<style lang="scss">
	.el-pager .active{
		background-color: #c71315 !important;
	}
	
</style>
