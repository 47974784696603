import request from '@/utils/request'

//1、banner图
export function Getbanner(data) {
  data['bannnerType'] = '1'
  return request({
    url: '/api/website/banner/getbanner',
    method: 'post',
    // headers: { "Content-Type": "application/json" },
    params: data
  })
}

//2、技能列表
export function TechnologyIntroducedList() {
  return request({
    url: '/api/website/technologyintroduced/technologyIntroducedList',
    method: 'post',
    // headers: { "Content-Type": "application/json" },
  })
}

//3、案例分类
export function caseClassList(data) {
  return request({
    url: '/api/website/caseclass/caseClassList',
    method: 'post',
	params: data
  })
}


//3、案例列表
export function queryProjectCase(data) {
  return request({
    url: '/api/website/projectcase/queryProjectCase',
    method: 'post',
	params: data
  })
}

//4、咨询列表
export function queryConsulting(data) {
  return request({
    url: '/api/website/consulting/queryConsulting',
    method: 'post',
	params: data
  })
}

//5、咨询详情
export function consultingInfo(data) {
  return request({
    url: '/api/website/consulting/consultingInfo',
    method: 'post',
	params: data
  })
}

//6、感兴趣的案例
export function interestedInProjectCase(data) {
  return request({
    url: '/api/website/projectcase/interestedInProjectCase',
    method: 'post',
	params: data
  })
}

//7、电话留言
export function addContactNumber(data) {
  return request({
    url: '/api/website/contactnumber/addContactNumber',
    method: 'post',
	data
  })
}

//8、数据统计
export function addArtificial(data) {
  return request({
    url: '/api/website/artificial/addArtificial',
    method: 'post',
	data
  })
}

//9、需求提交
export function addDemand(data) {
  return request({
    url: '/api/website/demand/addDemand',
    method: 'post',
	data
  })
}