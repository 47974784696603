import { render, staticRenderFns } from "./LrsSidebar.vue?vue&type=template&id=dfeab178&scoped=true"
import script from "./LrsSidebar.vue?vue&type=script&lang=js"
export * from "./LrsSidebar.vue?vue&type=script&lang=js"
import style0 from "./LrsSidebar.vue?vue&type=style&index=0&id=dfeab178&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfeab178",
  null
  
)

export default component.exports