import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showTopNav: true,
    showSidebarNav: true,
	information:false,
  },
  getters: {
  },
  mutations: {
	open_information(state,status){
		state.information = status
	},
  },
  actions: {
  },
  modules: {
  }
})
