<template>
	<div class="tipsBox">
		<div class="tips">
			<div class="bj">
				<img src="../static/img/tk.png" />
			</div>
			<div class="info">
				<div class="title">
					报价提示
				</div>
				<div class="text">请保持电话畅通，稍后将会与您联系！</div>
				<div class="button" @click="close">
					关闭
				</div>
			</div>
		</div>
		<div class="Mask"></div>
	</div>
	
</template>

<script>
	export default {
		data(){
			return{
				
			}
		},
		methods:{
			close(){
				this.$emit('closeTips')
			}
		}
	}
	
</script>

<style lang="scss" scoped>
	.tipsBox{
		.tips{
			width: 630px;
			height: 582px;
			position: fixed;
			z-index: 1000;
			top: 50%;
			left: 50%;
			margin-top: -315px;
			margin-left: -291px;
			.bj{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				img{
					width: 100%;
					height: 100%;
				}
			}
			.info{
				position: relative;
				z-index: 1;
				overflow: hidden;
				.title{
					font-size: 40px;
					font-family: PingFang SC-Heavy, PingFang SC;
					font-weight: 800;
					color: #372903;
					margin-top: 250px;
					text-align: center;
				}
				.text{
					font-size: 28px;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #787878;
					margin-top: 45px;
					text-align: center;
				}
				.button{
					width: 570px;
					line-height: 88px;
					background: #c71315;
					text-align: center;
					border-radius: 180px;
					margin: 70px auto 0;
					font-size: 28px;
					font-family: PingFang SC-Bold, PingFang SC;
					font-weight: bold;
					color: #fff;
					cursor: pointer;
				}
			}
			
		}
		.Mask{
			position: fixed;
			z-index: 10;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(0,0,0,0.3);
		}
	}
</style>