import axios from 'axios'

// 创建axios实例
const instance = axios.create({
    //baseURL: "http://192.168.1.132:10003",
	baseURL:"http://www.dixianchang.com",
	//baseURL:"",
    timeout: 1000 * 60
})
// 添加请求拦截器
instance.interceptors.request.use(config => {
    let token = window.sessionStorage.getItem("token")
    if (token) {
        // config.headers['Authorization'] = 'bearer ' + token
        config.headers['Authorization'] = 'bearer fuye1RGq5MxlGKmd_OONlBDfja0'
    }
    return config;
})

// 添加响应拦截器
instance.interceptors.response.use(response => {

    if (response.status !== 200) {
        // 用户token失效
        window.location = '/loginview'
    } else {
        return response
    }
})


export default instance;