var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"appear":"","enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"animate__animated animate__fadeOut"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTopNav),expression:"showTopNav"}],staticClass:"lrs-header-container"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"lrs-logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/img/11301@2x.png"),"alt":""}})])],1),_c('div',{staticClass:"menu"},[_c('router-link',{class:{ active: this.$route.path == '/' },attrs:{"to":"/"}},[_vm._v("首页")]),_c('router-link',{class:{ active: this.$route.path == '/SpecialtyView' },attrs:{"to":"/SpecialtyView"}},[_vm._v("擅长")]),_c('router-link',{class:{
            active:
              this.$route.path == '/CaseView' ||
              this.$route.path == '/CaseDetailVIew',
          },attrs:{"to":"/CaseView"}},[_vm._v("案例")]),_c('router-link',{class:{
            active:
              this.$route.path == '/Infoview' ||
              this.$route.path == '/SignDetailView' ||
              this.$route.path == '/SignNewsView' ||
              this.$route.path == '/NewDetailView',
          },attrs:{"to":"/Infoview"}},[_vm._v("资讯")]),_c('router-link',{class:{ active: this.$route.path == '/SystemView' },attrs:{"to":"/SystemView"}},[_vm._v("朗睿斯建站系统")]),_c('router-link',{class:{ active: this.$route.path == '/UsView' },attrs:{"to":"/UsView"}},[_vm._v("我们")]),_c('router-link',{class:{ active: this.$route.path == '/LoginView' },attrs:{"to":"/LoginView"}},[_vm._v("登录")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }