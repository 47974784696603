import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/IndexView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'IndexView',
    component: IndexView,
    // meta: {
    //   tag: "index",
    // },
  },
  {
    path: '/SpecialtyView',
    name: 'SpecialtyView',
    component: () => import('../views/SpecialtyView.vue')
  },
  {
    path: '/CaseView',
    name: 'CaseView',
    component: () => import('../views/CaseView.vue')
  },
  {
    path: '/Infoview',
    name: 'Infoview',
    component: () => import('../views/Infoview.vue')
  },
  {
    path: '/SystemView',
    name: 'SystemView',
    component: () => import('../views/SystemView.vue')
  },
  {
    path: '/UsView',
    name: 'UsView',
    component: () => import('../views/UsView.vue')
  },
  {
    path: '/LoginView',
    name: 'LoginView',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/CaseDetailVIew',
    name: 'CaseDetailVIew',
    component: () => import('../views/CaseDetailVIew.vue')
  },
  {
    path: '/SignDetailView',
    name: 'SignDetailView',
    component: () => import('../views/SignDetailView.vue')
  },
  {
    path: '/NewDetailView',
    name: 'NewDetailView',
    component: () => import('../views/NewDetailView.vue')
  }
  , {
    path: '/SignNewsView',
    name: 'SignNewsView',
    component: () => import('../views/SignNewsView.vue')
  },
  {
    path: '/Lookforward',
    name: 'Lookforward',
    component: () => import('../views/Lookforward.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})




export default router
