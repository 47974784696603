<template>
  <div class="lrs-contact-container">
    <div class="container">
      <div class="title" @click="$router.push('/Lookforward')">
        即刻与我们联系
      </div>
      <div class="subhead">助力企业提升品牌价值 开启高端建设全新体验</div>
      <div class="icon"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.lrs-contact-container {
  width: 100%;
  height: 500px;
  background-image: url(../assets/img/icontactbg.png);
  overflow: hidden;
  .container {
    width: 1404px;
    height: 100%;
    margin: 0 auto;
    .title {
      height: 79px;
      font-size: 60px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin: 163px auto 0;
      cursor: pointer;
	  text-align: center;
    }
    .subhead {
	  text-align: center;
	  letter-spacing: 6px;
      height: 35px;
      font-size: 26px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #626364;
      margin: 16px auto 0;
    }
    .icon {
      width: 70px;
      height: 4px;
      background: #c71315;
      border-radius: 90px;
      margin: 40px auto 0;
    }
  }
}
</style>
